var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.channel)?_c('v-container',{attrs:{"fluid":""}},[_c('v-toolbar',{attrs:{"flat":"","dense":"","color":"transparent"}},[_c('v-breadcrumbs',{attrs:{"items":[
        {
          exact: true,
          text: _vm.$t('epg.viewTitle'),
          to: { name: 'epg' },
        },
        {
          to: _vm.$route.path,
          text: _vm.channel.name,
        } ]}}),_c('v-spacer'),(!_vm.noTv)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_c('v-icon',[_vm._v("mdi-cast")])],1):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"py-0"},[_c('remote-control-select')],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(_vm._s(_vm.$t("modal.cancel")))]),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":!_vm.selectedTv.id},on:{"click":function($event){_vm.playEPGChannel({
              room_ip_id: _vm.selectedTv.id,
              start_channel_id: _vm.channel.id,
            }),
              (_vm.dialog = false)}}},[_vm._v(_vm._s(_vm.$t("modal.ok")))])],1)],1)],1),(_vm.channel && _vm.channel.events && _vm.channel.events.length > 0)?_c('v-timeline',{attrs:{"align-top":"","dense":""}},[_vm._l((_vm.channel.events),function(event,index){return [(event.type !== 'placeholder')?[(_vm.find(_vm.availableDates, { id: event.id }))?_c('v-chip',{key:index,staticClass:"white--text mb-3",staticStyle:{"width":"100%"},attrs:{"color":"primary","label":""}},[_vm._v(_vm._s(event.startDate))]):_vm._e(),_c('v-timeline-item',{key:event.id,attrs:{"href":'#' + event.id,"color":"primary","icon":"mdi-television-box","fill-dot":""}},[_c('v-card',{staticClass:"px-3",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"text-body-2"},[_vm._v(_vm._s(event.show_start_time)+" ")]),(event.percent_passed > 0 && event.percent_passed < 100)?_c('v-progress-linear',{attrs:{"value":event.percent_passed}}):_vm._e(),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-list',{staticClass:"pa-0",attrs:{"three-line":""}},[_c('v-list-item',{on:{"click":function($event){_vm.selectedEvent = event;
                        _vm.moreDialog = true;}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(event.event_name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(event.event_description)+" ")])],1)],1)],1)],1)],1)],1)],1)],1)]:_vm._e()]})],2):_vm._e(),_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.channel.isFetchingEvents,"text":""},on:{"click":function($event){return _vm.setLimit(_vm.limit + 10)}}},[_vm._v(_vm._s(_vm.$t("general.loadMore")))])],1),_c('v-dialog',{model:{value:(_vm.moreDialog),callback:function ($$v) {_vm.moreDialog=$$v},expression:"moreDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.selectedEvent.event_name)+" ")]),_c('v-card-text',[_c('div',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.selectedEvent.show_start_time)+" - "+_vm._s(_vm.selectedEvent.show_end_time)+" ")]),_vm._v(" "+_vm._s(_vm.selectedEvent.event_description))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.moreDialog = false}}},[_vm._v(_vm._s(_vm.$t("general.close")))])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }